import hdqPagination from "@/components/hdqPagination"
import etable from "@/views/ElectronicDocuments/etable"
import dirDialog from "@/views/ElectronicDocuments/components/dirDialog"
import insuranceDialog from "@/components/insuranceDialog"
export default {
    components: {
        hdqPagination,
        dirDialog,
        etable,
        insuranceDialog
    },
    data() {
        return {
            list: []
        }
    },
    methods: {

        async getList() {
            const { current, size } = this.$refs.pagination;
            const { params } = this.$route;
            let { listParams } = this;
            let time = {
                initiatedAtFrom: null,
                initiatedAtTo: null
            }
            if (listParams.initiated) {
                const [star, end] = listParams.initiated;
                time.initiatedAtFrom = star;
                time.initiatedAtTo = end;
                let { obj, ...initiated } = listParams;
                listParams = obj;
            }
            const { status, data } = await this.$api.getContract({ current, size, ...listParams, ...params, ...time })
            if (status == 200) {
                this.list = data.records;
                this.$refs.pagination.createPagination(data)
            }

        },
        guidang(data) {
            this.$refs.dirDialog.show(data)
        },
        downFile(fileId) {
            this.$store.dispatch('documents/getFile', {
                fileId, callback: ({ downloadUrl }) => {

                    window.open(downloadUrl)
                }
            })
        },
        insuranceDialogShow(data){
            this.contractUuid = data.contractUuid
            this.$refs.insuranceDialog.show(data)
        },
        operate({ type, data }) {
            if (type == 'guidang') {
                this.guidang(data)
            } else if (type == 'chakan') {
                this.downFile(data.files[0].fileId)
            } else if (type == "xiazai") {
                this.downFile(data.files[0].fileId)
            } else if (type == "chuzheng") {
                this.$router.push({ path: '/accurateEvidence/extension/apply', query: {data:JSON.stringify(data)} })
            }else if( type=="hetongbao"){
                this.insuranceDialogShow(data)
            }
        }
    },
}