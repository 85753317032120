/*
* @Author: 曹俊杰
* @Date: 2024-09-29 15:23:19
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-10-13 09:37:01
*/合同宝Icon

<template>
    <div class="hetongbao">
        <div v-if="value === 0" class="type0" @click="buyClick">立即购买</div>
        <div v-else-if="value === 1" class="type1 item">
            <span style="margin-left: 0;">无保障</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <circle cx="7" cy="7" r="7" fill="#EEEEEE" />
                <path
                    d="M6.63905 8.53438L6.4203 4.29062L6.39843 3H7.60155L7.57968 4.29062L7.36093 8.53438H6.63905ZM6.99999 11.2359C6.60624 11.2359 6.29999 10.9516 6.29999 10.5141C6.29999 10.0766 6.61718 9.78125 6.99999 9.78125C7.3828 9.78125 7.69999 10.0766 7.69999 10.5141C7.69999 10.9516 7.3828 11.2359 6.99999 11.2359Z"
                    fill="#999999" />
            </svg>
        </div>
        <div v-else-if="value === 2" class="type2 item">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M5.0664 19.0502C5.0904 18.892 5.622 18.1417 8.13162 17.8714C9.13619 17.763 10.4954 18.0937 12.2076 18.8635C11.4965 19.3685 10.6101 19.7099 9.54957 19.8894C6.78569 20.3562 5.23708 19.2031 5.07973 19.0627L5.0664 19.0502ZM1.74869 13.791C1.75669 13.735 3.15329 13.2256 5.66647 14.4879C6.57767 14.9449 7.51111 15.7823 8.46678 17.0002C7.22041 17.1762 6.10206 17.09 5.11084 16.7424C3.02707 16.0108 1.73713 13.8763 1.74869 13.791ZM0.00981249 8.34325C0.0835916 8.2997 2.53364 8.70418 3.98803 10.3737C4.67967 11.1685 5.17837 12.1792 5.4833 13.4078C4.00313 12.9731 2.88213 12.4166 2.11938 11.7374C0.0151673 9.86519 -0.039068 8.37081 0.00981249 8.34325ZM1.17705 4.06277C1.25262 4.03343 3.22171 5.02287 4.38184 6.7404C4.93303 7.55828 5.20239 8.5744 5.18816 9.79141C3.87781 8.89977 3.00569 8.20456 2.5701 7.70763C0.98413 5.8941 1.12815 4.08233 1.17705 4.06277ZM4.49567 0.000124952C4.7339 0.0472344 5.9367 1.64564 6.10562 3.62097C6.18563 4.55974 6.01494 5.49495 5.59356 6.42572C4.67523 5.1047 4.1525 3.88944 4.02449 2.77821C3.76936 0.553062 4.4441 -0.00966781 4.49567 0.000124952Z"
                    fill="url(#paint0_linear_440_1998)" />
                <defs>
                    <linearGradient id="paint0_linear_440_1998" x1="6.10382" y1="0" x2="6.10382" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#FFD189" />
                        <stop offset="1" stop-color="#E0BF6C" />
                    </linearGradient>
                </defs>
            </svg>

            <span>保障中</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M7.34888 19.0502C7.32488 18.892 6.79328 18.1417 4.28367 17.8714C3.2791 17.763 1.91984 18.0937 0.207649 18.8635C0.918833 19.3685 1.80516 19.7099 2.86572 19.8894C5.62959 20.3562 7.1782 19.2031 7.33555 19.0627L7.34888 19.0502ZM10.6666 13.791C10.6586 13.735 9.262 13.2256 6.74882 14.4879C5.83761 14.9449 4.90417 15.7823 3.94851 17.0002C5.19487 17.1762 6.31323 17.09 7.30444 16.7424C9.38822 16.0108 10.6782 13.8763 10.6666 13.791ZM12.4055 8.34325C12.3317 8.2997 9.88165 8.70418 8.42726 10.3737C7.73562 11.1685 7.23691 12.1792 6.93198 13.4078C8.41215 12.9731 9.53315 12.4166 10.2959 11.7374C12.4001 9.86519 12.4544 8.37081 12.4055 8.34325ZM11.2382 4.06277C11.1627 4.03343 9.19357 5.02287 8.03344 6.7404C7.48225 7.55828 7.21289 8.5744 7.22712 9.79141C8.53748 8.89977 9.40959 8.20456 9.84518 7.70763C11.4312 5.8941 11.2871 4.08233 11.2382 4.06277ZM7.91962 0.000124952C7.68138 0.0472344 6.47858 1.64564 6.30966 3.62097C6.22966 4.55974 6.40034 5.49495 6.82172 6.42572C7.74005 5.1047 8.26278 3.88944 8.39079 2.77821C8.64593 0.553062 7.97118 -0.00966781 7.91962 0.000124952Z"
                    fill="url(#paint0_linear_440_2000)" />
                <defs>
                    <linearGradient id="paint0_linear_440_2000" x1="6.31147" y1="0" x2="6.31147" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#FFD189" />
                        <stop offset="1" stop-color="#E0BF6C" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
        <div v-else-if="value === 3" class="type3 item">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M5.0664 19.0502C5.0904 18.892 5.622 18.1417 8.13162 17.8714C9.13619 17.763 10.4954 18.0937 12.2076 18.8635C11.4965 19.3685 10.6101 19.7099 9.54957 19.8894C6.78569 20.3562 5.23708 19.2031 5.07973 19.0627L5.0664 19.0502ZM1.74869 13.791C1.75669 13.735 3.15329 13.2256 5.66647 14.4879C6.57767 14.9449 7.51111 15.7823 8.46678 17.0002C7.22041 17.1762 6.10206 17.09 5.11084 16.7424C3.02707 16.0108 1.73713 13.8763 1.74869 13.791ZM0.00981249 8.34325C0.0835916 8.2997 2.53364 8.70418 3.98803 10.3737C4.67967 11.1685 5.17837 12.1792 5.4833 13.4078C4.00313 12.9731 2.88213 12.4166 2.11938 11.7374C0.0151673 9.86519 -0.039068 8.37081 0.00981249 8.34325ZM1.17705 4.06277C1.25262 4.03343 3.22171 5.02287 4.38184 6.7404C4.93303 7.55828 5.20239 8.5744 5.18816 9.79141C3.87781 8.89977 3.00569 8.20456 2.5701 7.70763C0.98413 5.8941 1.12815 4.08233 1.17705 4.06277ZM4.49567 0.000124952C4.7339 0.0472344 5.9367 1.64564 6.10562 3.62097C6.18563 4.55974 6.01494 5.49495 5.59356 6.42572C4.67523 5.1047 4.1525 3.88944 4.02449 2.77821C3.76936 0.553062 4.4441 -0.00966781 4.49567 0.000124952Z"
                    fill="url(#paint0_linear_528_8990)" />
                <defs>
                    <linearGradient id="paint0_linear_528_8990" x1="6.10382" y1="0" x2="6.10382" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#666666" />
                        <stop offset="1" stop-color="#999999" />
                    </linearGradient>
                </defs>
            </svg>

            <span>已到期</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M7.34888 19.0502C7.32488 18.892 6.79328 18.1417 4.28367 17.8714C3.2791 17.763 1.91984 18.0937 0.207649 18.8635C0.918833 19.3685 1.80516 19.7099 2.86572 19.8894C5.62959 20.3562 7.1782 19.2031 7.33555 19.0627L7.34888 19.0502ZM10.6666 13.791C10.6586 13.735 9.262 13.2256 6.74882 14.4879C5.83761 14.9449 4.90417 15.7823 3.94851 17.0002C5.19487 17.1762 6.31323 17.09 7.30444 16.7424C9.38822 16.0108 10.6782 13.8763 10.6666 13.791ZM12.4055 8.34325C12.3317 8.2997 9.88165 8.70418 8.42726 10.3737C7.73562 11.1685 7.23691 12.1792 6.93198 13.4078C8.41215 12.9731 9.53315 12.4166 10.2959 11.7374C12.4001 9.86519 12.4544 8.37081 12.4055 8.34325ZM11.2382 4.06277C11.1627 4.03343 9.19357 5.02287 8.03344 6.7404C7.48225 7.55828 7.21289 8.5744 7.22712 9.79141C8.53748 8.89977 9.40959 8.20456 9.84518 7.70763C11.4312 5.8941 11.2871 4.08233 11.2382 4.06277ZM7.91962 0.000124952C7.68138 0.0472344 6.47858 1.64564 6.30966 3.62097C6.22966 4.55974 6.40034 5.49495 6.82172 6.42572C7.74005 5.1047 8.26278 3.88944 8.39079 2.77821C8.64593 0.553062 7.97118 -0.00966781 7.91962 0.000124952Z"
                    fill="url(#paint0_linear_528_8992)" />
                <defs>
                    <linearGradient id="paint0_linear_528_8992" x1="6.31147" y1="0" x2="6.31147" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#666666" />
                        <stop offset="1" stop-color="#999999" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
        <div v-else-if="value === 4" class="type4 item">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M5.0664 19.0502C5.0904 18.892 5.622 18.1417 8.13162 17.8714C9.13619 17.763 10.4954 18.0937 12.2076 18.8635C11.4965 19.3685 10.6101 19.7099 9.54957 19.8894C6.78569 20.3562 5.23708 19.2031 5.07973 19.0627L5.0664 19.0502ZM1.74869 13.791C1.75669 13.735 3.15329 13.2256 5.66647 14.4879C6.57767 14.9449 7.51111 15.7823 8.46678 17.0002C7.22041 17.1762 6.10206 17.09 5.11084 16.7424C3.02707 16.0108 1.73713 13.8763 1.74869 13.791ZM0.00981249 8.34325C0.0835916 8.2997 2.53364 8.70418 3.98803 10.3737C4.67967 11.1685 5.17837 12.1792 5.4833 13.4078C4.00313 12.9731 2.88213 12.4166 2.11938 11.7374C0.0151673 9.86519 -0.039068 8.37081 0.00981249 8.34325ZM1.17705 4.06277C1.25262 4.03343 3.22171 5.02287 4.38184 6.7404C4.93303 7.55828 5.20239 8.5744 5.18816 9.79141C3.87781 8.89977 3.00569 8.20456 2.5701 7.70763C0.98413 5.8941 1.12815 4.08233 1.17705 4.06277ZM4.49567 0.000124952C4.7339 0.0472344 5.9367 1.64564 6.10562 3.62097C6.18563 4.55974 6.01494 5.49495 5.59356 6.42572C4.67523 5.1047 4.1525 3.88944 4.02449 2.77821C3.76936 0.553062 4.4441 -0.00966781 4.49567 0.000124952Z"
                    fill="url(#paint0_linear_440_1998)" />
                <defs>
                    <linearGradient id="paint0_linear_440_1998" x1="6.10382" y1="0" x2="6.10382" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#FFD189" />
                        <stop offset="1" stop-color="#E0BF6C" />
                    </linearGradient>
                </defs>
            </svg>

            <span>执行中</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M7.34888 19.0502C7.32488 18.892 6.79328 18.1417 4.28367 17.8714C3.2791 17.763 1.91984 18.0937 0.207649 18.8635C0.918833 19.3685 1.80516 19.7099 2.86572 19.8894C5.62959 20.3562 7.1782 19.2031 7.33555 19.0627L7.34888 19.0502ZM10.6666 13.791C10.6586 13.735 9.262 13.2256 6.74882 14.4879C5.83761 14.9449 4.90417 15.7823 3.94851 17.0002C5.19487 17.1762 6.31323 17.09 7.30444 16.7424C9.38822 16.0108 10.6782 13.8763 10.6666 13.791ZM12.4055 8.34325C12.3317 8.2997 9.88165 8.70418 8.42726 10.3737C7.73562 11.1685 7.23691 12.1792 6.93198 13.4078C8.41215 12.9731 9.53315 12.4166 10.2959 11.7374C12.4001 9.86519 12.4544 8.37081 12.4055 8.34325ZM11.2382 4.06277C11.1627 4.03343 9.19357 5.02287 8.03344 6.7404C7.48225 7.55828 7.21289 8.5744 7.22712 9.79141C8.53748 8.89977 9.40959 8.20456 9.84518 7.70763C11.4312 5.8941 11.2871 4.08233 11.2382 4.06277ZM7.91962 0.000124952C7.68138 0.0472344 6.47858 1.64564 6.30966 3.62097C6.22966 4.55974 6.40034 5.49495 6.82172 6.42572C7.74005 5.1047 8.26278 3.88944 8.39079 2.77821C8.64593 0.553062 7.97118 -0.00966781 7.91962 0.000124952Z"
                    fill="url(#paint0_linear_440_2000)" />
                <defs>
                    <linearGradient id="paint0_linear_440_2000" x1="6.31147" y1="0" x2="6.31147" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#FFD189" />
                        <stop offset="1" stop-color="#E0BF6C" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
        <div v-else-if="value === 5" class="type5 item">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M5.0664 19.0502C5.0904 18.892 5.622 18.1417 8.13162 17.8714C9.13619 17.763 10.4954 18.0937 12.2076 18.8635C11.4965 19.3685 10.6101 19.7099 9.54957 19.8894C6.78569 20.3562 5.23708 19.2031 5.07973 19.0627L5.0664 19.0502ZM1.74869 13.791C1.75669 13.735 3.15329 13.2256 5.66647 14.4879C6.57767 14.9449 7.51111 15.7823 8.46678 17.0002C7.22041 17.1762 6.10206 17.09 5.11084 16.7424C3.02707 16.0108 1.73713 13.8763 1.74869 13.791ZM0.00981249 8.34325C0.0835916 8.2997 2.53364 8.70418 3.98803 10.3737C4.67967 11.1685 5.17837 12.1792 5.4833 13.4078C4.00313 12.9731 2.88213 12.4166 2.11938 11.7374C0.0151673 9.86519 -0.039068 8.37081 0.00981249 8.34325ZM1.17705 4.06277C1.25262 4.03343 3.22171 5.02287 4.38184 6.7404C4.93303 7.55828 5.20239 8.5744 5.18816 9.79141C3.87781 8.89977 3.00569 8.20456 2.5701 7.70763C0.98413 5.8941 1.12815 4.08233 1.17705 4.06277ZM4.49567 0.000124952C4.7339 0.0472344 5.9367 1.64564 6.10562 3.62097C6.18563 4.55974 6.01494 5.49495 5.59356 6.42572C4.67523 5.1047 4.1525 3.88944 4.02449 2.77821C3.76936 0.553062 4.4441 -0.00966781 4.49567 0.000124952Z"
                    fill="url(#paint0_linear_440_1998)" />
                <defs>
                    <linearGradient id="paint0_linear_440_1998" x1="6.10382" y1="0" x2="6.10382" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#FFD189" />
                        <stop offset="1" stop-color="#E0BF6C" />
                    </linearGradient>
                </defs>
            </svg>

            <span>已完结</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path
                    d="M7.34888 19.0502C7.32488 18.892 6.79328 18.1417 4.28367 17.8714C3.2791 17.763 1.91984 18.0937 0.207649 18.8635C0.918833 19.3685 1.80516 19.7099 2.86572 19.8894C5.62959 20.3562 7.1782 19.2031 7.33555 19.0627L7.34888 19.0502ZM10.6666 13.791C10.6586 13.735 9.262 13.2256 6.74882 14.4879C5.83761 14.9449 4.90417 15.7823 3.94851 17.0002C5.19487 17.1762 6.31323 17.09 7.30444 16.7424C9.38822 16.0108 10.6782 13.8763 10.6666 13.791ZM12.4055 8.34325C12.3317 8.2997 9.88165 8.70418 8.42726 10.3737C7.73562 11.1685 7.23691 12.1792 6.93198 13.4078C8.41215 12.9731 9.53315 12.4166 10.2959 11.7374C12.4001 9.86519 12.4544 8.37081 12.4055 8.34325ZM11.2382 4.06277C11.1627 4.03343 9.19357 5.02287 8.03344 6.7404C7.48225 7.55828 7.21289 8.5744 7.22712 9.79141C8.53748 8.89977 9.40959 8.20456 9.84518 7.70763C11.4312 5.8941 11.2871 4.08233 11.2382 4.06277ZM7.91962 0.000124952C7.68138 0.0472344 6.47858 1.64564 6.30966 3.62097C6.22966 4.55974 6.40034 5.49495 6.82172 6.42572C7.74005 5.1047 8.26278 3.88944 8.39079 2.77821C8.64593 0.553062 7.97118 -0.00966781 7.91962 0.000124952Z"
                    fill="url(#paint0_linear_440_2000)" />
                <defs>
                    <linearGradient id="paint0_linear_440_2000" x1="6.31147" y1="0" x2="6.31147" y2="20"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.145" stop-color="#FFD189" />
                        <stop offset="1" stop-color="#E0BF6C" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
        <div v-else>--</div>
    </div>
</template>

<script>
import selectData from "@/mixins/selectData.js"
export default {
    mixins: [selectData],
    props: {
        value: {
            type: Number,
            default: 0
        },
        item: {
            type: Object,
            default: () => ({})
        }
    },
    methods:{
        buyClick(){
            this.$emit('buyClick')
        }
    }

}
</script>

<style lang="scss" scoped>
.hetongbao {
    .item {
        display: flex;
        align-items: center;

        span {
            margin: 0 4px;
        }
    }

    .type0 {
        color: #0062FF;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }

    .type1 {
        color: #666;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .type2 {
        background: linear-gradient(180deg, #0062FF 0%, #003B99 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
    }

    .type3 {
        color: #666;

        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
    }

    .type4 {
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
        background: linear-gradient(113deg, #FF8412 4.92%, #994F0B 94.37%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .type5 {
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
        background: linear-gradient(96deg, #BF59F7 0.98%, #311256 97.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
