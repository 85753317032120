<template>
    <el-table border :data="list" style="width: 100%;background:transparent">
        <el-table-column prop="name" label="合同名称" width="180">
            <template slot-scope="scope">
                <span style="color:#0062FF">{{ scope.row.subject }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="发起时间" width="120" min-width="60" show-overflow-tooltip>
            <template slot-scope="scope">
                <span class="pointer moving">{{ scope.row.createdAt | timeFormat }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="finishedAt" label="截止时间" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
                <span class="pointer moving">{{ scope.row.finishedAt | timeFormat }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="signers" label="签约方信息">
            <template slot-scope="scope">
                <span>{{ tableSigners(scope) || "--" }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="state" label="合同状态" width="100">
            <template slot-scope="scope">
                <qianshuIcon :value="scope.row.state" />
            </template>
        </el-table-column>
        <el-table-column prop="address" label="归档管理">
            <template slot-scope="{row}">
                <div @click="operate('guidang', row)" class="pointer primary">
                    <span class="el-icon-ali-zidingyihetongfenlei"></span>
                    <span style="margin-left: 6px;">{{ row.dirLabel || '待归档' }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="insuranceVersion" label="合同保">
            <template slot-scope="scope">
                <hetongbaoIcon :value="scope.row.insuranceState" :item="scope.row" @buyClick="operate('hetongbao',scope.row)"/>
                <!-- <hetongbaoIcon :value="5" :item="scope.row" /> -->
            </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
            <template slot-scope="{row}">
                <span @click="operate('chakan', row)">查看</span>
                <span>｜</span>
                <!-- <span @click="()=>window.open($oss+scope.row.files[0].url)">下载</span> -->
                <span @click="operate('xiazai', row)">下载</span>
                <span>｜</span>
                <span class="primary" @click="operate('chuzheng', row)">出证</span>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import qianshuIcon from "@/components/qianshuIcon"
import hetongbaoIcon from "@/components/hetongbaoIcon"
export default {
    components: {
        qianshuIcon,
        hetongbaoIcon
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        operate(type, data) {
            this.$emit('operate', { type, data })
        },
        tableSigners(scope) {
            return scope.row.signers.map(res => res.operatorName).join("、")
        },
    }

}
</script>
